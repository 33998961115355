import React from 'react'
import { Link } from 'gatsby'
import BlogLayout from '../../components/Layouts/BlogLayout'
import SEO from '../../components/SEO'

const title = "How to run AB tests"
const description = "To run an effective split test, you should use a five-step framework. This helps you follow a set process for each test and gather learnings."
const summary = "A/B testing (also known as split testing) is the gift that keeps on giving. When you run A/B tests, you gain valuable insights into how your users respond to  different messaging, CTAs, and even creative; anything really. The sky's the limit! Running tests allows you to optimise for continuous improvement – it’s good for you, and it’s good for your visitors."

const HowToRunABTests: React.FunctionComponent = () => (
  <BlogLayout title={title} summary={summary}>
    <SEO title={title} description={description} />
    <h2>
      Steps to run an effective split test
    </h2>
    <p>
      To run an effective A/B test, you should use a five-step framework. This helps you follow a set process for each test and gather learnings.
    </p>
    <ol>
      <li>
        🤔 Research and hypothesise
      </li>
      <li>
        🧪 Create your variations
      </li>
      <li>
        🥼 Run your experiment
      </li>
      <li>
        🤓 Analyse results
      </li>
      <li>
        🧬 Apply and/or iterate
      </li>
    </ol>
    <h3>
      🤔 Research and hypothesise
    </h3>
    <p>
      In split testing, like anything else, prior preparation sets you up for success. Before you get click-happy with setting up variations, you should take a step back and look at the wider picture. This is your opportunity to turn into a mad scientist and dive into data.
    </p>
    <p>
      Not sure where to start? Try something that will give you quick wins, such as:
    </p>
    <ul>
      <li>
        low conversion rates (like underperforming open rates on your emails or few enquiries through your website)
      </li>
      <li>
        high drop-off rates (like abandoned carts or bounce rates).
      </li>
    </ul>
    <p>
      This is data you can use to determine both your biggest challenges and your biggest opportunities. If, for example, you have a landing page that has a consistently high amount of traffic, but underperforms for revenue conversions, you may want to test product placements on that page; in that instance, your goal would be transactions generated from the landing page.
    </p>
    <p>
      It’s likely that you’ll generate a huge list of potential opportunities, so start with your biggest opportunities and low-hanging fruit. Another way to prioritise marketing collateral that you want to test is to create a testing backlog and use scoring framework commonly used in product; a Reach, Impact, Confidence, Effort (RICE) scoring framework.
    </p>
    <p>
      Once you have done your research and have a thorough understanding of how users are interacting with your current messaging, grab your smoking pipe because it’s time to generate a hypothesis.
    </p>
    <h3>
      🧪 Set up your split test variations
    </h3>
    <p>
      When you’re setting up your A/B test, you need one or more variations to test against the existing version (the control). When you set up more than one challenger variation, it’s known as A/B/n testing.
    </p>
    <p>
      Many A/B testing tools will guide you through the variant set up. Some even have a visual editor that makes creating your variants easy.
    </p>
    <p>
      Regardless of your testing tool, quality assurance is an important step to ensure your test gives you actionable insights.
    </p>
    <h3>
      🥼 Run your experiment
    </h3>
    <p>
      Now you’ve come to the part that can take the longest; it’s time to wait for your experiment to run.
    </p>
    <p>
      Once you kick off your experiment and wait for visitors to engage with your variants, all you can do is sit back and watch the conversions roll in. Your testing software should be randomly assigning visitors to the control or the challenger/s, measuring and comparing each interaction with the test variants.
    </p>
    <p>
      It’s a bit of a waiting game here; while you don’t want to end your test without giving it a fair chance to gather valuable insights, some tests will never reach statistical significance.
    </p>
    <p>
      It can also be tempting to check in on your variants daily, but if your volumes aren’t high, your efforts could be better spent elsewhere while your sample size builds to a level where you can feel confident in the results. Due to the different personas that might interact with your test variants over a period of time, it’s best to run your test for at least one calendar week.
    </p>
    <p>
      If your test has been running for several weeks but you haven’t seen a large number of traffic or conversions, it might be time to pause this test and look for some more fruitful results with another test.
    </p>
    <p>
      Conversely, if you’ve run your test for at least one calendar week and have seen a significant number of conversions for at least one variant, it’s time to analyse your data.
    </p>
    <h3>
      🤓 Analyse your test’s results
    </h3>
    <p>
      A word of caution before proceeding: statistical significance is not the only part of the equation. Why? Simply due to conversion rates.
    </p>
    <p>
      If variation A has had 1000 visitors and 250 conversions, that’s a 25% conversion rate. If variation B has had only 1 conversion, but also only 1 visitor, that’s a 100% conversion rate.
    </p>
    <p>
      Because many statistical significance calculators don’t take this into account, it can be easy to overlook the obvious: in this instance, you simply don’t have enough information to know which variant will truly convert at a higher rate.
    </p>
    <p>
      Once you’re satisfied with your sample size and conversion rate and have enough results to gather meaningful insights from, go ahead and use our <Link to="/tools/statistical-significance-calculator/">A/B test calculator</Link> to determine the results.
    </p>
    <h3>
      🧬 Apply or iterate
    </h3>
    <p>
      Hurrah, your test was significantly significant and you are ready to roll out your winning variation to 100% of your visitors.
    </p>
    <p>
      Let’s talk about A/B tests that are easy for you to implement from a marketing perspective but are significantly more complicated to build out in full (for example, perhaps making changes to your checkout flow).
    </p>
    <p>
      If you’ve ever asked a developer to “just push some buttons” to make what you consider to be relatively easy changes to your website, you’ve probably seen an answering look of disgust.
    </p>
    <p>
      Some changes that appear deceptively simple can be complex engineering work that requires a lot of skill and experience to implement – and it’s certainly never as easy as clicking a few buttons. (Really, that’s not a good way to make friends with developers.)
    </p>
    <p>
      Luckily, there is a bit of a hack you can use to give your website people time to develop a robust solution while also looking after your commercial interests: in your A/B testing tool, turn off your losing variation, and switch your winning variant to be distributed to 100% of your visitors.
    </p>
    <p>
      Once your test is finalised, you might want to test another hypothesis, another channel, or another cohort of users.
    </p>
    <p>
      A/B testing is the game that never ends.
    </p>
    <p>
      Good luck!
    </p>
  </BlogLayout >
)

export default HowToRunABTests