import React from "react"
import { Link } from "gatsby"
import Navbar from "./Navbar"
import Footer from "./Footer"
import { VerticalSpace } from "../VerticalSpace"
import "../../style/style.scss"


interface BlogLayoutProps {
  title: string
  summary: string
  cta?: string
}

const BlogLayout: React.FunctionComponent<BlogLayoutProps> = ({ title, summary, children, cta }) => {
  return (
    <React.Fragment>
      <Navbar />
      <main className="container mt-3">
        <VerticalSpace height={50} />
        <header className="jumbotron bg-quinary text-secondary text-white">
          <h1>{title}</h1>
          <p className="lead">{summary}</p>
        </header>
        <div className="blog-padding">
          {children}
        </div>
        <VerticalSpace height={50} />
      </main>

      <Footer />
    </React.Fragment>
  )
}

export default BlogLayout